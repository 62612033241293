import axios from '@/axios.js'

export default {
  fetchRatePlanPolicies ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('rate-plan-policy')
        .then(({data: response}) => {
          commit('SET_RATE_PLAN_POLICIES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchRatePlanPolicy (context, ratePlanPolicyId) {
    return new Promise((resolve, reject) => {
      axios.get(`rate-plan-policy/${ratePlanPolicyId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeRatePlanPolicy (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('rate-plan-policy', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateRatePlanPolicy (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`rate-plan-policy/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyRatePlanPolicy ({ commit }, ratePlanPolicyId) {
    return new Promise((resolve, reject) => {
      axios.delete(`rate-plan-policy/${ratePlanPolicyId}`)
        .then((response) => {
          commit('REMOVE_RECORD', ratePlanPolicyId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
